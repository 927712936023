.google-auth-button-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.google-auth-button {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border: 0.1rem solid rgba(0, 0, 0, 0.1);
  width: 15rem;
  height: 3rem;
  border-radius: 3rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  background-color: white;
}
.google-auth-button:hover {
  cursor: pointer;
  background-color: rgba(245, 245, 245, 1);
}
